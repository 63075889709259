import React from "react";
import UrlImage from "../components/UrlImage";
import "../index.css";

function Home() {
  return (
    <div className="flex h-screen justify-center items-center">
      <div className="sm:w-1/2 md:w-1/2 lg:w-1/3 rounded-lg shadow-lg bg-gray-300 border-t-8 border-blue-600 p-8 font-mono text-center">
        <h1 className="text-2xl font-medium truncate">Alistair MacLean</h1>
        <div className="flex justify-center">
          <UrlImage image="/images/cv.png" alt="CV" url="/cv" />
          <UrlImage image="/images/gitlab.png" alt="Gitlab" url="/gitlab" />
          <UrlImage image="/images/linkedin.png" alt="LinkedIn" url="/linkedin" />
        </div>
      </div>
    </div>
  );
}

export default Home;
