import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ParticleBackground from "./components/ParticleBackground";
import Home from "./components/Home";

function Redirect(props) {
  window.location = props.loc;
  return null;
}

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ParticleBackground />
        <Home />
        <Router>
          <Route path="/cv" component={() => (<Redirect loc="/files/cv.pdf" />)} />
          <Route path="/gitlab" component={() => (<Redirect loc="https://git.alistair.ninja/public/" />)} />
          <Route path="/linkedin" component={() => (<Redirect loc="https://www.linkedin.com/in/a-maclean/" />)} />
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
