import React from "react";
import Fade from "react-reveal/Fade";

function UrlImage(props) {
  return (
    <React.Fragment>
      <a href={props.url}>
        <img className="block p-2 m-2 max-h-24" src={props.image} alt={props.alt} />
        <Fade bottom>
          <p>{props.alt}</p>
        </Fade>
      </a>
    </React.Fragment>
  );
}

export default UrlImage;
